import { FC } from "react";
import { Modal } from "antd";

type PrivacyModalProps = {
  open: boolean;
  title: string;
  policy: string;
  onOk: () => void;
  onCancel: () => void;
};

const PrivacyModal: FC<PrivacyModalProps> = ({
  open,
  title,
  policy,
  onOk,
  onCancel,
}) => {
  return (
    <Modal open={open} title={title} onOk={onOk} onCancel={onCancel}>
      {/* <p>{policy}</p> */}

      <p>
        En cumplimiento a la Ley General de Protección de Datos Personales en
        Posesión de Sujetos Obligados y la Ley de Protección de Datos Personales
        en Posesión de Sujetos Obligados para el Estado de Quintana Roo, la
        Secretaría de Bienestar del Estado de Quintana Roo, a través de la
        Subsecretaría de Desarrollo Humano y la Dirección de Desarrollo
        Comunitario, informa que es la responsable del tratamiento de los Datos
        Personales que nos proporcione para efecto de participar en el programa
        "Conecta Quintana Roo", los cuales estarán protegidos con fundamento en
        la legislación mencionada con antelación, y demás normativa aplicable a
        la materia.
        <br />
        Para mayor detalle, usted puede consultar nuestro Aviso de Privacidad
        Integral en:{" "}
        <a target="https://sebien.qroo.gob.mx/avisos-de-privacidad-de-la-sebien/">
          https://sebien.qroo.gob.mx/avisos-de-privacidad-de-la-sebien/
        </a>
        , en la sección{" "}
        <span style={{ fontWeight: 900 }}>“Avisos de Privacidad 2025”</span>.
      </p>
      {/* <p>
        En cumplimiento a la Ley General de Protección de Datos Personales en
        Posesión de Sujetos Obligados y la Ley de Protección de Datos Personales
        en Posesión de Sujetos Obligados para el Estado de Quintana Roo. La
        Secretaria de Bienestar del Estado de Quintana Roo, a través de la
        Agencia de Seguridad Alimentaria, informa que es la responsable del
        tratamiento de los Datos Personales que nos proporcione para efecto de
        participar en el programa{" "}
        <span style={{ fontWeight: 900 }}>"Comemos Tod@s"</span> , los cuales
        estarán protegidos con fundamento en la legislación mencionada con
        antelación, y demás normativa aplicable a la materia.
        <br />
        <br />
        Para mayor detalle, usted puede consultar nuestro Aviso de Privacidad
        Integral en:
        <a target="_blank" href="https://qroo.gob.mx/sedeso/avisos-de-privacidad-2022-2027/">
          https://qroo.gob.mx/sedeso/avisos-de-privacidad-2022-2027
        </a>
        , en la sección de{" "}
        <span style={{ fontWeight: 900 }}>
          “Avisos de Privacidad de la SEBIEN”
        </span>
        .
      </p> */}
    </Modal>
  );
};

export default PrivacyModal;
