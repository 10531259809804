import axios from "axios";
import { IGizmo, IGizmoData, gnov } from "flowy-3-core";
import config from "../config";

export interface IUserCredentials {
  "access-token"?: string;
  "auth-form-fid"?: string;
  /**
   * Key where the token generated by the Auth libraries will be stored
   */
  Authorization?: string;
  client?: string;
  "token-type"?: string;
  uid: string;
  expiry?: string;
  /**
   * In case it's an API user this key will be specified
   */
  "api-key"?: string;
}

export interface IUserV2 {
  id: string;
  username: string;
  role?: string;
  email?: string;
  /**
   * User credentials
   */
  credentials?: IUserCredentials;
  gizmos?: IGizmo;
  /**
   * We're using variables because it's how its stored on the user
   */
  variables?: {
    [qfid: string]: IGizmoData;
  };
  data?: any;
}

interface ILogin {
  email?: string;
  username?: string;
  password: string;
  fingerprint?: string;
}

interface IAuthentication {
  init: () => Promise<void>;
  login: (props: ILogin) => Promise<void>;
  logout: () => Promise<void>;
  isAuthenticated: () => Promise<boolean>;
  getUser: () => Promise<any>;
  getUserCredentials: () => Promise<any>;
}

class JWTAuthentication implements IAuthentication {
  public init = (): Promise<void> => {
    return new Promise<void>((resolve) => {
      resolve();
    });
  };

  public login = ({
    username,
    password,
    fingerprint,
  }: ILogin): Promise<void> => {
    return new Promise<void>(async (resolve, reject) => {
      try {
        const loginResponse = await axios({
          method: "post",
          url: `${config.apiUrl}/auth/sign_in`,
          data: {
            username,
            password,
            fingerprint,
            clientVersion: '3',
            clientPlatform: 'web',
          },
        });

        if (loginResponse.status === 200) {
          const { data, headers } = loginResponse;

          localStorage.setItem("access-token", headers["access-token"]);
          localStorage.setItem("client", headers["client"]);
          localStorage.setItem("expiry", headers["expiry"]);
          localStorage.setItem("token-type", headers["token-type"]);
          localStorage.setItem("uid", headers["uid"]);

          const { user: u } = data;

          localStorage.setItem("id", u.id);
          localStorage.setItem("role", u.role);
          localStorage.setItem("username", u.username);
          localStorage.setItem("variables", JSON.stringify(u.answers));

          if (u.answers) {
            const data: any = {};
            for (const fid in u.answers) {
              const value = gnov(u.answers, `${fid}.0.value`);

              if (value) {
                const gizmoData = { v: value };
                data[fid] = gizmoData;
              }
            }

            localStorage.setItem("data", JSON.stringify(data));
          }
        }
      } catch (e) {
        if (e.response?.data) {
          const { message } = e.response.data;

          reject({ message });
        } else {
          reject(e);
        }
      }

      resolve();
    });
  };

  public logout = (): Promise<void> => {
    return new Promise<void>(async (resolve) => {
      const keysToRemove = [
        "access-token",
        "auth-form-fid",
        "client",
        "expiry",
        "token-type",
        "uid",
        "id",
        "role",
        "username",
        "data",
        "variables",
        "gizmos",
      ];

      for (const key of keysToRemove) {
        localStorage.removeItem(key);
      }

      resolve();

      window.location.href = "/";
    });
  };

  public isAuthenticated = (): Promise<boolean> => {
    return new Promise<boolean>((resolve) => {
      const accessToken = localStorage.getItem("access-token");
      const formAccessToken = localStorage.getItem("form-access-token");
      if (accessToken || formAccessToken) {
        resolve(true);
      } else {
        resolve(false);
      }
    });
  };

  public getUser = (): Promise<any> => {
    return new Promise<any>((resolve) => {
      const accessToken = localStorage.getItem("access-token");
      const authFormFid = localStorage.getItem("auth-form-fid");
      const client = localStorage.getItem("client");
      const expiry = localStorage.getItem("expiry");
      const tokenType = localStorage.getItem("token-type");
      const uid = localStorage.getItem("uid");

      const username = localStorage.getItem("username");
      const role = localStorage.getItem("role");
      const id = localStorage.getItem("id");

      const variables = localStorage.getItem("variables");
      const data = localStorage.getItem("data");
      const gizmos = localStorage.getItem("gizmos");

      if (
        accessToken &&
        client &&
        expiry &&
        tokenType &&
        uid &&
        username &&
        id &&
        role
      ) {
        const u: IUserV2 = {
          id,
          username,
          role,
          credentials: {
            client,
            "access-token": accessToken,
            "token-type": tokenType,
            uid,
            expiry,
          },
        };
        if (authFormFid && u.credentials) {
          u.credentials["auth-form-fid"] = authFormFid;
        }
        if (variables) {
          u.variables = JSON.parse(variables);
        }
        if (gizmos) {
          u.gizmos = JSON.parse(gizmos);
        }
        if (data) {
          u.data = JSON.parse(data);
          u.variables = JSON.parse(data);
        }

        resolve(u);
      } else {
        resolve(undefined);
      }
    });
  };

  public setUser = (user: IUserV2): Promise<void> => {
    return new Promise<void>(async (resolve) => {
      localStorage.setItem("username", user.username);
      localStorage.setItem("id", user.id);
      localStorage.setItem("role", "form-auth");

      if (user.credentials) {
        localStorage.setItem("access-token", user.credentials["access-token"]!);
        localStorage.setItem("client", user.credentials.client!);
        localStorage.setItem("expiry", user.credentials.expiry!);
        localStorage.setItem("token-type", user.credentials["token-type"]!);
        localStorage.setItem("uid", user.credentials.uid);
        localStorage.setItem(
          "auth-form-fid",
          user.credentials["auth-form-fid"]!
        );

        if (user.variables) {
          localStorage.setItem("variables", JSON.stringify(user.variables));
        }
        if (user.gizmos) {
          localStorage.setItem("gizmos", JSON.stringify(user.gizmos));
        }
      }

      resolve();
    });
  };

  public getUserCredentials = (): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      const accessToken = localStorage.getItem("access-token");
      const client = localStorage.getItem("client");
      const expiry = localStorage.getItem("expiry");
      const tokenType = localStorage.getItem("token-type");
      const uid = localStorage.getItem("uid");

      if (accessToken && client && tokenType && uid && expiry) {
        const credentials: IUserCredentials = {
          "access-token": accessToken,
          client,
          "token-type": tokenType,
          uid,
          expiry,
        };

        resolve(credentials);
      } else {
        reject();
      }
    });
  };
}

let auth: any | undefined;

if (config.auth.method === "jwt") {
  auth = new JWTAuthentication();
}

export type { IAuthentication };
export default auth;
